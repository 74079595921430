import React, { FC } from "react"
import { RouteComponentProps } from "@reach/router"
import styled from "styled-components"
import { PageWrapper } from "components/Shared/PageWrapper"
import { BackgroundImage } from "components/Shared/BackgroundImage"
import { PageTitle } from "components/Shared/PageTitle"
import { ContentWrapper } from "components/Shared/ContentWrapper"
import { IFeaturedContentProps } from "types"
import Card from "components/Shared/Card"
import { formatContentImgSrcToHttps, generateContentObject } from "utils"
import { WrapperCards } from "components/Shared/WrapperCards"
import { StyledContainer } from "components/Shared/Container"
import SEO from "components/Seo"
import Breadcrumbs from "components/Breadcumbs"

export interface IPageProps {
  title: string
  link: string
  date: string
  content?: string
  slug?: string
  type: string
  id: string
  template?: string
  seo?: {
    title: string
    metaDesc: string
    metaKeywords: string
    opengraphImage: {
      sourceUrl: string
    }
    canonical: string
    breadcrumbs: [
      {
        text: string
        url: string
      }
    ]
    opengraphSiteName: string
    opengraphTitle: string
    opengraphUrl: string
  }
  children?: {
    nodes?: [
      {
        id: string
        link: string
        slug: string
        menuOrder: number
        featuredImage: {
          node: {
            sourceUrl: string
          }
        }
      }
    ]
    path: string
  }
  posts?: {
    nodes: [
      {
        id: string
        content: string
        date: string
        featuredImage: {
          node: {
            sourceUrl: string
          }
        }
        link: string
        nodeType: string
        slug: string
        title: string
      }
    ]
  }
  categoryImage?: {
    categoryImage: {
      sourceUrl: string
    }
  }
  iframe?: string
  photos?: { photos: [{ image: { id: string; sourceUrl: string } }] }
  featuredImage?: {
    node: {
      sourceUrl: string
    }
  }
  featuredContent?: { featuredContent: IFeaturedContentProps[] }
}

export interface IPageTemplateProps extends RouteComponentProps {
  pageContext: IPageProps
}

const Wrapper = styled(PageWrapper)`
  z-index: 1;
  position: relative;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
`

const StyledContentWrapper = styled(ContentWrapper)`
  margin-top: 0;
`

const PageTemplate: FC<IPageTemplateProps> = ({ pageContext }) => {
  const generateChildren = children => {
    return children
      .sort((a, b) => a.menuOrder - b.menuOrder)
      .map(child => {
        return <Card content={generateContentObject(child)} key={child.id} />
      })
  }

  return (
    <>
      <SEO
        title={pageContext.seo ? pageContext.seo.title : pageContext.title}
        description={pageContext.seo ? pageContext.seo.metaDesc : null}
        image={
          pageContext.seo &&
          pageContext.seo.opengraphImage &&
          pageContext.seo.opengraphImage.sourceUrl
            ? pageContext.seo.opengraphImage.sourceUrl
            : null
        }
      />
      <BackgroundImage
        style={{
          backgroundImage: `url(${
            pageContext.featuredImage
              ? pageContext.featuredImage.node.sourceUrl
              : null
          })`,
        }}
      />
      <Wrapper className="content-wrapper">
        <PageTitle>{pageContext.title}</PageTitle>
        <Breadcrumbs
          breadcrumbs={pageContext.seo ? pageContext.seo.breadcrumbs : null}
        />
        {Boolean(
          pageContext.children ? pageContext.children.nodes.length : 0
        ) ? (
          <StyledContainer>
            <WrapperCards>
              {generateChildren(pageContext.children.nodes)}
            </WrapperCards>
          </StyledContainer>
        ) : (
          <StyledContentWrapper>
            <div
              dangerouslySetInnerHTML={{
                __html: formatContentImgSrcToHttps(pageContext.content),
              }}
            />
          </StyledContentWrapper>
        )}
      </Wrapper>
    </>
  )
}

export default PageTemplate
