import styled from "styled-components"
import { colors } from "styles/colors"
import { spacing } from "styles/spacing"
import { breakpoint } from "styles/breakpoints"
import { typography } from "styles/typography"

export const ContentWrapper = styled.div`
  margin-top: ${spacing.lg}px;
  max-width: 1920px;
  position: relative;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.8);
  padding: ${spacing.md}px ${spacing.sm}px;
  @media ${breakpoint.md} {
    margin: 0 ${spacing.md}px;
    padding: ${spacing.xl}px ${spacing.xl}px;
  }
  @media ${breakpoint.lg} {
    margin: 0 auto;
    width: 80%;
    padding: ${spacing.lg}px ${spacing.xxl}px;
  }
  @media ${breakpoint.xl} {
    margin: 0 auto;
    width: 80%;
    font-size: 22px;
    line-height: 1.45;
    letter-spacing: 0.4px;
  }
`
