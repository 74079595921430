import React, { FC } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { spacing } from "styles/spacing"

interface IBreadcrumbsProps {
  breadcrumbs: [
    {
      text: string
      url: string
    }
  ]
}

const Wrapper = styled.div`
  text-align: center;
  position: relative;
  display: table;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
  margin: ${spacing.sm}px auto;
  padding: 6px 12px 4px 12px;
  & a {
    text-transform: capitalize;
  }
  & span span {
    margin: 0 4px;
  }
`

const Breadcrumbs: FC<IBreadcrumbsProps> = ({ breadcrumbs }) => {
  if (breadcrumbs.length === 1) return null

  const siteUrl = "http://admin.mightyevent.se"

  const formatBreadcrumb = url => {
    if (url.includes(siteUrl)) {
      return url.replace(siteUrl, "")
    }
    return url
  }
  return (
    <Wrapper>
      {breadcrumbs.map((breadcrumb, index, arr) => {
        return (
          <span key={breadcrumb.url}>
            <Link
              to={`${
                breadcrumb.text === "Hem"
                  ? "/"
                  : formatBreadcrumb(breadcrumb.url)
              }`}
              aria-label={`go to ${breadcrumb.text}`}
            >
              <span dangerouslySetInnerHTML={{ __html: breadcrumb.text }} />
            </Link>
            {arr.length - 1 !== index ? <span>-</span> : null}
          </span>
        )
      })}
    </Wrapper>
  )
}

export default Breadcrumbs
