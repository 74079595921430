import React, { FC } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { spacing } from "styles/spacing"
import { typography } from "styles/typography"
import { formatDate, formatImgSrcUrl, textTruncate } from "utils"
import mightyLogo from "../../images/mighty-event-logo.png"

interface ICardProps {
  content: {
    link: string
    imgSrc?: string
    date?: string
    excerpt?: string
    title: string
    type: string
    iframe?: string
  }
}

export const Wrapper = styled(props => <Link {...props} />)`
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  height: 100%;
  & img {
    transition: all 0.25s ease-in;
  }
  & :hover {
    opacity: 1;
  }
  & :hover img {
    filter: brightness(50%);
    transform: scale(1.01) translate(-50%, -50%);
  }
`

export const Content = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  display: block;
  height: 35%;
  max-height: 150px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: ${spacing.md}px;
  & h3 {
    font-family: ${typography.heading};
    font-size: ${typography.size.md};
    font-weight: bold;
    overflow: hidden;
    text-transform: uppercase;
    & span {
      display: block;
      font-size: ${typography.size.xs};
      margin-bottom: ${spacing.xs}px;
    }
  }
`

export const WrapperIframe = styled.div`
  height: 65%;
  width: 100%;
  & iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const ContentVideoCard = styled(Content)`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 35%;
`

export const Excerpt = styled.p`
  overflow: hidden;
  height: 38px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`

export const StyledImg = styled.div`
  max-height: 100%;
  width: auto;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
`

const Card: FC<ICardProps> = ({ content }) => {
  if (!content) return null
  const { link, excerpt, imgSrc, title, date, type, iframe } = content

  const VideoCard = () => (
    <>
      <WrapperIframe dangerouslySetInnerHTML={{ __html: iframe }} />
      <ContentVideoCard
        style={{ height: !excerpt && !date ? "auto" : "height: 35%" }}
      >
        <h3>
          {textTruncate(title, 20)}
          <span>{formatDate(date)}</span>
        </h3>
      </ContentVideoCard>
    </>
  )

  return (
    <Wrapper to={`${link}`}>
      {type === "Film" ? (
        <VideoCard />
      ) : (
        <>
          <StyledImg
            style={{
              backgroundImage: `url("${
                imgSrc ? formatImgSrcUrl(imgSrc) : mightyLogo
              }")`,
            }}
          />
          <Content
            style={{ height: !excerpt && !date ? "auto" : "height: 35%" }}
          >
            <h3>
              {textTruncate(title, 20)}
              {date ? <span>{formatDate(date)}</span> : null}
            </h3>
            {excerpt ? (
              <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }}></Excerpt>
            ) : null}
          </Content>
        </>
      )}
    </Wrapper>
  )
}

export default Card
