import styled from "styled-components"
import { spacing } from "styles/spacing"
import { breakpoint } from "styles/breakpoints"

export const WrapperCards = styled.div`
  display: grid;
  column-gap: ${spacing.md}px;
  row-gap: ${spacing.md}px;
  margin-bottom: ${spacing.md}px;
  & > a {
    min-height: 400px;
  }
  @media ${breakpoint.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`
