import styled from "styled-components"
import { breakpoint } from "styles/breakpoints"
import { typography } from "styles/typography"
import { spacing } from "styles/spacing"

export const PageTitle = styled.h1`
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  hyphens: auto;
  -webkit-hyphens: auto;
  max-width: 100%;
  -webkit-animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  overflow-wrap: break-word;
  -ms-hyphens: auto;
  hyphens: auto;
  padding: 12px 0 8px 0;
  background-color: rgba(0, 0, 0, 0.8);
  font-family: ${typography.heading};
  font-size: ${typography.size.xl};
  @media ${breakpoint.md} {
    font-size: ${typography.size.xxl};
  }
  @media ${breakpoint.lg} {
    font-size: 100px;
    padding: 20px 0 18px 0;
  }
  @media ${breakpoint.xl} {
    font-size: 150px;
    padding: 24px 0 22px 0;
  }
  @media ${breakpoint.xxxl} {
    font-size: 176px;
  }
`
export const PageTitleWithDate = styled(PageTitle)`
  & span {
    font-size: ${typography.size.lg};
    display: block;
    margin-bottom: ${spacing.md}px;
  }
`

export const PageTitleWithDateSmall = styled(PageTitleWithDate)`
  font-size: ${typography.size.md};
  @media ${breakpoint.md} {
    font-size: ${typography.size.lg};
  }
  @media ${breakpoint.lg} {
    font-size: 80px;
  }
  @media ${breakpoint.xl} {
    font-size: 100px;
  }

  & span {
    font-size: 60%;
  }
`
