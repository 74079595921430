import styled from "styled-components"
import { spacing } from "styles/spacing"
import { breakpoint } from "styles/breakpoints"
import { headerHeight } from "styles/variables"

export const PageWrapper = styled.div`
  min-height: calc(100vh - ${headerHeight}px);
  display: flex;
  z-index: 1;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  flex-direction: column;
  padding: 0 ${spacing.sm}px;
  @media ${breakpoint.md} {
    padding: 0;
  }
`
