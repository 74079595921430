import styled from "styled-components"

export const BackgroundImage = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  margin-bottom: 0 !important;
  background-position: center;
  -webkit-animation: kenburns-top 8s ease-out both;
  animation: kenburns-top 8s ease-out both;
`
